
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.animation-container {
    max-width: fit-content;
    margin: 1rem;

    @media (max-width: $screen-md) {
        margin: 0;
    }
}

.player {
    width: 4rem;
    height: 4rem;

    @media (max-width: $screen-md) {
        width: 3rem;
        height: 3rem;
    }
}