
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.itg-home-content {
    background-color: $itg-white;
    padding: 4rem 2rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;

    // Adjust the padding for smaller screens!
    @media (max-width: $screen-xl) {
      padding: 4rem 0;
    }

    @media (max-width: $screen-lg) {
      padding: 3rem 0;
    }

    &__title {
      text-align: center;

      @media (max-width: $screen-lg) {
        text-align: start;
        font-size: 1rem; //~16px
      }
    }
}

  .info-text{
    flex-shrink: 1;
  }

  .info-section {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: $screen-lg) {
      flex-flow: column nowrap;
    }

    &__card {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 2rem;

      @media (max-width: $screen-lg) {
        margin: .5rem;
      }
    }
  }

  .info-card-text {
    @media (max-width: $screen-lg) {
      font-size: .88rem; // ~14px
    }
  }

  .animated-content {
    @media (max-width: $screen-lg) {
      margin: 1rem 1rem 1rem 0;
    }
  }