
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.tab {
    &__container {
        width: 90%;
        background-color: $itg-white;
        border-radius: 0.3rem;
        margin-bottom: 1rem;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.10), 0 2px 0 0 rgba(0, 0, 0, 0.12);
    }

	&__header {
		display: flex;
        flex-flow: row nowrap;
		align-items: center;
        justify-content: flex-start;
        background-color: $itg-grey--light-2;
        border-radius: 0.3rem;

		&__item {
            flex: 1 0 auto;
            max-width: 13rem;
			text-align: center;
            color: $itg-grey--mid-3;
			padding: .9rem 0;
			cursor: pointer;

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            & > * {
                color: $itg-grey--mid-3;
            }

            &:first-child {
                border-top-left-radius: .3rem;
            }

            &:last-child {
                border-top-right-radius: .3rem;
            }

            svg {
                font-size: 1.4rem;
                margin-right: .5rem;
            }

            &--selected {
                color: $itg-blue;
                background-color: $itg-white;
                border-top: $itg-blue solid .3rem;

                & > * {
                    color: $itg-blue;
                }
            }

            &__text {
                line-height: 1.23; // Allow text to be perfectly aligned with icons
                @media (max-width: $screen-sm) {
                    display: none;
                }
            }
		}
	}

	&__body {
		margin: 0 auto;
		width: 100%;
        background-color: $itg-white;
        padding: 1rem;
        border-radius: .3rem;
	}
}

.btnGroup {
    &__container {
        width: 98%;
        margin-bottom: .5rem;
    }

	&__header {
		display: grid;
        grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
        margin: 0 0.1rem 1rem;
        align-items: center;
        justify-content: center;
        justify-items: stretch;
        align-content: center;
        gap: 0.5rem;

		&__item {
            background-color: $itg-white !important;
            border-radius: .2rem;
            
            // Adjust size according to screen sz
            min-width: 9.2rem;
            flex: 1 1 auto;

            height: 2.97rem;
            filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.15));
            & > * {
                color: $itg-grey--mid-3 !important;
            }


            svg {
                font-size: 1.4rem;
                margin-right: .5rem;
                color: $itg-grey--mid-3 !important;
            }

            &:hover {
                background-color: $itg-grey--mid-3;
            }

            &--selected {
                color: $itg-blue !important;
                background-color: $itg-white;
                border-top: $itg-blue solid .3rem;
                &:hover {
                    background-color: $itg-white;
                }

                & > * {
                    color: $itg-blue !important;
                }

                svg {
                    color: $itg-blue !important;
                }
            }
		}
	}

	&__search {
		margin: 0 auto;
		width: 95%;
        background-color: $itg-white;
        padding: .3rem;
        border-radius: .3rem;
        // box-shadow: .1rem solid rgba($itg-grey--mid,0.25);
        filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.15));

        display: flex;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;
        column-gap: .2rem;

        // Expand all the way to use the max screen size on sm screens
        @media (max-width: $screen-sm) {
            width: 100%;
            padding: .8rem;
        }

        &__form {
            display: flex;
            width: 95%;
            align-content: center;

            @media (max-width: $screen-sm) {
                width: 100%;                
            }
        }
	}
}