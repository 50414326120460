
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
// Class for the 5 menu options presented on the home page.
.itg-menu-options {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 0 5rem;
    text-align: center;
    padding-bottom: 1rem;

    & > a {
        color: $itg-black;
    }

    // Ensure the grid is centered and does not spread to thin across the
    // home page.
    &-grid {
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
        max-width: 70vw;

        // As the page gets smaller we allow the home buttons more room
        // to fill as they will not look too spread apart and will form
        // the least amount of grid rows possible.
        @media (max-width: $screen-md) { max-width: 80vw; }
        @media (max-width: $screen-xs) { max-width: 90vw; }
    }

    @media (max-width: $screen-sm) {
        display: none;
    }

    @media (max-width: $screen-xs) {
        margin: 0 .5rem;
    }
}

.menu-option-button-small{
    width: 95% !important;
}

.menu-option-button-width {
    min-width: 11rem;
}

.banner-subtitle {
    &__container {
        text-align: left;
        // takes as much space as the search-bar above
        width: 92%;

        @media (max-width: $screen-md) {
            margin: .5rem 0;
            width: 96%; // takes as much space as search-bar on mobile
        }
    }

    &__text {
        @media (max-width:$screen-md) {
            display: none;
        }    
    }
    &__link {
        color: $itg-white;
        font-weight: 700;
    
        text-decoration: underline;    
        text-underline-offset: .125rem; // ~2px
    
        &:hover {
            color: $itg-grey;
            text-underline-offset: .125rem; // ~2px
        }
    
        @media (max-width: $screen-md) {
            color: $itg-white;
            text-underline-offset: 0.2rem;
            text-decoration-thickness: 0.1rem;
        }
    }
}


// Styles for the main menu option button (Property, Company e.t.c.)
.menu-option-button {
    color: $itg-black;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: .3rem;
    font-size: $desktop-body-font-size-lg;
    height: 2.8rem;
    flex-basis: 10rem;
    padding: 0 .1rem !important;
    float: left;
    box-shadow: none;
    text-align: center;

    @media (max-width: $screen-md) {
        font-size: $desktop-body-font-size-sm;
    }

    @media (max-width: $screen-sm) {
        float: none;
    }

    svg {
        display: inline-block;
        fill: $itg-black;
        height: 1.5rem;
        margin-right: 0.4rem;

        @media (max-width: $screen-md) {
            height: 1.25rem;
        }
    }
}

// What style to show when menu option button hovered or is selected.
.menu-option-button:hover,
.menu-option-button-selected {
    background-color: $itg-black !important;
    color: $itg-white !important;

    span { color: $itg-white; fill:  $itg-white }

    svg {
        display: inline-block;
        fill: $itg-white;
        height: 1.5rem;

        @media (max-width: $screen-md) {
            height: 1.25rem;
        }
    }
}

.itg-home-pane__center {
    padding: 5rem 0;
    background-color: $itg-purple;
    padding: 5rem 0;
    background-color: #AE46D3;
    width: 95%;
    height: 22rem;
    
    // extra pedding on big screens so its contents remains in the center
    padding-left: 10%;
    padding-right: 5%; // Note the width of 95% removes 5% on the right

    border-top-right-radius: 15rem;
    border-bottom-right-radius: 15rem;

    // Adjust the extra pedding to center its contents on lg screen
    @media (max-width: $screen-lg) {
        padding: 5.5rem 5%;
    }
    
    // Adjust the extra pedding to center its contents on md or smaller screen
    @media (max-width: $screen-md) {        
        width: 100%;
        padding: 1rem 2% 1rem;
        background-color: $itg-purple;

        // Remove right-edge borders
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        
        // Auto resizes to fit its contents
        height: auto;
    }

    // Class that contains the actual searchbar component.
    &-searchbar {
        display: flex;
        flex-direction: column;
        justify-content: space-around;        

        // Allow extra space on the top to fit the new search
        @media (max-width: $screen-md) {
            margin-top: 1rem;
            align-items: center;
        }
    }
}

.itg-home-pane__center__service {
    border-radius: 0.6rem;
    padding: 0.6rem;
    margin: 0.5rem 1rem;
    width: 20%;

    &:hover {
        .itg-home-pane__center__text {
        transition: all 300ms;
        color: $itg-orange;
        }

        text-decoration: none;
    }

    @media (max-width: $screen-md) {
        margin: 0.5rem;
    }
}

.itg-home-pane__center__text {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: $itg-font-weight-bold;
    color: $itg-black;
    opacity: 0.3;
    line-break: normal;

    @media (max-width: $screen-lg) {
        font-size: $mobile-body-font-size-med;
    }

    @media (max-width: $screen-xs) {
        font-size: 10pt;
    }

    @media (max-width: $screen-xxs) {
        font-size: 8pt;
    }

    &--highlighted {
        opacity: 1;
    }
}

.itg-trustpilot-wrapper {
    margin-top: 0rem;
}
